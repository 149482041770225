const axios = window.axios
const url = '/api/v2/companies/'
const urlFeed = '/api/v2/companies/feed/'
const urlList = '/api/v2/companies/list/'
const urlListChoose = '/api/v2/companies/choose/'
const urlStrip = '/api/v2/companies/strip/'
const urlInfo = '/api/v2/companies/info/'
const urlImage = '/api/v2/companies/images/'
const urlTypes = '/api/v2/companies/payment-types/'



export const CompaniesApiUrls = {
  // companies
  async getCompanies(params) {
    return (await axios.get(`${url}`, {
      params: params
    })).data;
  },
  async createCompany(body) {
    return (await axios.post(`${url}`, body)).data;
  },
  async getCompany(id) {
    return (await axios.get(`${url}${id}/`)).data;
  },
  async setCompany(id, body) {
    return (await axios.put(`${url}${id}/`, body)).data;
  },
  async deleteCompany(id) {
    return (await axios.delete(`${url}${id}/`)).data;
  },
  async exportCompanies(){
    return (await axios.get(`${url}export/`, {
      responseType: "blob",
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    })).data;
  },


  // feed еда
  async getFeeds(params) {
    return (await axios.get(`${urlFeed}`, {
      params: params
    })).data.results;
  },
  async createFeed(body) {
    return (await axios.post(`${urlFeed}`, body)).data;
  },
  async getFeed(id) {
    return (await axios.get(`${urlFeed}${id}/`)).data;
  },
  async setFeed(id, body) {
    return (await axios.put(`${urlFeed}${id}/`, body)).data;
  },
  async deleteFeed(id) {
    return (await axios.delete(`${urlFeed}${id}/`)).data;
  },


  // strip популярность
  async getStrips(params) {
    return (await axios.get(`${urlStrip}`, {
      params: params
    })).data.results;
  },
  async createStrip(body) {
    return (await axios.post(`${urlStrip}`, body)).data;
  },
  async getStrip(id) {
    return (await axios.get(`${urlStrip}${id}/`)).data;
  },
  async setStrip(id, body) {
    return (await axios.put(`${urlStrip}${id}/`, body)).data;
  },
  async deleteStrip(id) {
    return (await axios.delete(`${urlStrip}${id}/`)).data;
  },


  // list справа лист городов для изменения города
  async getCompaniesList(params) {
    return (await axios.get(`${urlList}`, {
      params: params
    })).data.results;
  },



  // choose изменить город справа лист городов для изменения города
  async setCompanyList(body) {
    return (await axios.post(`${urlListChoose}`, body)).data;
  },


  // info информация об объекте
  async getInfo() {
    return (await axios.get(`${urlInfo}`)).data;
  },
  async setInfo(body) {
    return (await axios.patch(`${urlInfo}`, body)).data;
  },
  async setInfoImage(formdata) {
    return (await axios.put(`${urlInfo}upload-main-image/`, formdata)).data;
  },
  async setInfoTemplate(formdata) {
    return (await axios.put(`${urlInfo}template/`, formdata)).data;
  },
  async setInfoSftp(body) {
    return (await axios.patch(`${urlInfo}`, body)).data;
  },



  // info images нформация об объекте
  async getInfoImages() {
    return (await axios.get(`${urlImage}`)).data;
  },
  async createInfoImage(formData) {
    return (await axios.post(`${urlImage}`, formData)).data;
  },
  async getInfoImage(id) {
    return (await axios.get(`${urlImage}${id}/`)).data;
  },
  // async setInfoImage(id, body){
  //     return (await axios.put(`${urlImage}${id}/`, body)).data;
  // },
  async deleteInfoImage(id) {
    return (await axios.delete(`${urlImage}${id}/`)).data;
  },



  // виды оплат
  async getTypes(params) {
    return (await axios.get(`${urlTypes}`, {
      params: params
    })).data;
  },
  async getType(id) {
    return (await axios.get(`${urlTypes}${id}/`)).data;
  },
  async createType(body) {
    return (await axios.post(`${urlTypes}`, body)).data;
  },
  async setType(body, id) {
    return (await axios.put(`${urlTypes}${id}/`, body)).data;
  },
  async deleteType(id) {
    return (await axios.delete(`${urlTypes}${id}/`)).data;
  },

}