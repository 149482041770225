<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    :height="$mobileCheck ? '50' : '73'"
  >
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <v-spacer />
    <div class="mx-3" />
    <v-row
      :class="checkInput ? 'mt-5 mr-3' : 'mt-md-3 justify-center'"
      v-if="currentRouteName === 'Календарь'"
    >
      <v-text-field
        v-if="$mobileCheck && checkInput && !isFoodTickerManager"
        label="Поиск - По ФИО, номер бронирования"
        @change="getSearch"
      />
      <v-text-field
        v-if="!$mobileCheck && !isFoodTickerManager"
        label="Поиск - По ФИО, номер бронирования"
        @change="getSearch"
      />
      <v-btn
        class="ml-2 mt-1"
        small
        icon
        @click="checkInput = !checkInput"
        v-if="$mobileCheck && !isFoodTickerManager"
      >
        <v-icon>
          mdi-magnify
        </v-icon>
      </v-btn>

      <v-btn
        class="ml-2"
        small
        fab
        v-if="!$mobileCheck && !isFoodTickerManager"
      >
        <v-icon>
          mdi-magnify
        </v-icon>
      </v-btn>
    </v-row>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="!checkInput && !isFoodTickerManager"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-badge :color="badgeColor" overlap :content="taskCounter">
            <v-icon>
              mdi-bell
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <app-bar-item v-for="(item, index) in notifications" :key="index">
          <v-btn @click="goRoute(item)" text>{{ item.value }}</v-btn>
        </app-bar-item>
      </v-list>
    </v-menu>
    <div v-if="true && !checkInput">
      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        v-model="menu"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
            <v-icon>
              mdi-domain
            </v-icon>
          </v-btn>
        </template>
        <v-list :tile="false" nav style="height: 300px; overflow-y: auto;">
          <div>
            <v-text-field
              class="pa-3"
              solo
              hide-details
              v-model="header_input"
              placeholder="Название компании"
              @input="handleInput"
            >
            </v-text-field>
            <app-bar-item v-for="(n, i) in companies" :key="`item-${i}`">
              <v-btn
                style="justify-content: flex-start;"
                block
                text
                @click="changeCompany(n)"
                v-text="n.name"
              />
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>
    </div>
    <v-menu
      v-if="!checkInput"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-list nav>
        <app-bar-item v-if="!isFoodTickerManager">
          <v-btn text to="/profile">
            Профиль
          </v-btn>
        </app-bar-item>
        <app-bar-item>
          <v-btn text @click="logout()">
            Выйти
          </v-btn>
        </app-bar-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { VHover, VListItem } from "vuetify/lib";
import { mapState, mapMutations } from "vuex";
import user from "../../../../store/auth";
import { CompaniesApiUrls } from "@/services/accommodationRequests/companies.api.js";
import { BookingApiUrls } from "@/services/accommodationRequests/booking.api.js";
import { FOOD_TICKET_MANAGER } from "@/shared/config";

export default {
  name: "DashboardCoreAppBar",
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checkInput: false,
    taskCounter: 0,
    badgeColor: "red",
    notifications: [],
    companies: [],
    isSuperUser: false,
    CompaniesApiUrls: CompaniesApiUrls,
    menu: false,
    header_input: null,
    companies_upd: [],
    isFoodTickerManager: false
  }),
  computed: {
    ...mapState(["drawer"]),
    currentRouteName() {
      return this.$route.name;
    },
    getUserRole() {
      return user.getters.getUserRole;
    }
  },
  watch: {
    "$store.state.triggerCheckNotificationsLoad"() {
      // обновить компании и таски
      this.loadNotifications();
      this.loadCompanies();
    },
    "$route.name"() {
      if (this.getUserRole === FOOD_TICKET_MANAGER) {
        this.isFoodTickerManager = true;
      }
    }
  },
  mounted() {
    if (this.getUserRole === FOOD_TICKET_MANAGER) {
      this.isFoodTickerManager = true;
    }
    if (user.getters.getIsSuperuser === "true") {
      this.isSuperUser = true;
    } else {
      this.isSuperUser = false;
    }
  },
  created() {
    this.loadNotifications();
    this.loadCompanies();
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      getSearch: "SEARCH_BAR"
    }),
    async loadNotifications() {
      let resp = await BookingApiUrls.getNotifications();
      this.taskCounter = resp.length;
      let task = "задач";
      let task_count = this.taskCounter % 100;
      if (11 <= task_count && task_count <= 19) {
        task = "задач";
      } else {
        let tcount = task_count % 10;
        if (tcount === 1) {
          task = "задача";
        } else if (1 < tcount && tcount < 5) {
          task = "задачи";
        } else {
          task = "задач";
        }
      }
      this.notifications = [];
      this.notifications.push({
        type: "task",
        value: `У вас сегодня ${this.taskCounter} ${task}`
      });
      if (this.taskCounter < 1) {
        this.badgeColor = "white";
      }

      if (localStorage.getItem("is_superuser") === "true") {
        this.isSuperUser = true;
      } else {
        this.isSuperUser = false;
      }
    },
    async loadCompanies() {
      this.companies = await this.CompaniesApiUrls.getCompaniesList();
      this.companies_upd = [...this.companies];
    },
    handleInput() {
      this.companies = this.companies_upd.filter(e =>
        e.name
          .toString()
          .toLowerCase()
          .includes(this.header_input.toString().toLowerCase())
      );
    },
    goRoute(item) {
      if (item.type == "task") {
        this.$router.push("/tasks");
      }
    },
    logout() {
      // delRefreshToken
      user.commit("delToken");
      user.commit("delRefreshToken");
      this.$router.push("/auth/login");
    },
    // изменить компанию суперадмином
    async changeCompany(company) {
      this.menu = false;
      let response = await this.CompaniesApiUrls.setCompanyList({
        company: company.id
      });
      user.commit("setCompany", response.id);
      user.commit("setHasWidget", response.has_widget);
      user.commit("setCheckInTime", response.check_in_time);
      user.commit("setCheckOutTime", response.check_out_time);
      user.commit("setIsBookingTime", response.booking_time);
      let arr = ["rest_zone", "hotel", "guest_house", "sanatorium"];
      if (arr.includes(response.accommodation_type)) {
        user.commit("setCompanyType", "simple");
      } else if (
        response.accommodation_type == "spa" ||
        response.accommodation_type == "leisure"
      ) {
        user.commit("setCompanyType", "is_leisure");
      }
      this.$router.push("/");
      setTimeout(() => {
        document.location.reload();
      }, 0);
    }
  }
};
</script>

<style>
.v-badge__badge {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 1px !important;
  font-size: 10px !important;
}
</style>
